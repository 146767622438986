import '../styles/globals.css';
import 'tailwindcss/tailwind.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect } from 'react';
import UserProvider from '../components/UserProvider';

function MyApp({ Component, pageProps }) {


  return (
    <UserProvider>
      <Component {...pageProps} />
    </UserProvider>
  )
}

export default MyApp
